/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddPos()" v-if="type === 0"
          >Agregar caja</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de cajas: <b>{{ pos.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="pos"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Pos"
        :checked-rows.sync="checkedPos"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        detailed
        detail-key="_id"
        checkbox-position="left"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        default-sort="CLAVE"
      >
        <template #empty>
          <div class="has-text-centered">No hay cajas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddPos :posInformation="props.row" v-if="type === 0" />

                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="openOpenPos(props.row)"
                    v-if="type === 1 && props.row.STATUS === 'CE'"
                    >Abrir caja</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="type === 1"
                    @click="openPosCortes(props.row)"
                    >Ver cortes de caja</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="type === 1 && props.row.STATUS === 'AB'"
                    @click="openAddManualMovement(props.row, 0)"
                    >Agregar movimiento manual ingreso (Caja chica
                    entrada)</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="type === 1 && props.row.STATUS === 'AB'"
                    @click="openAddManualMovement(props.row, 1)"
                    >Agregar movimiento manual egreso (Caja chica
                    salida)</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddPos from "./AddPos";
import PosCortes from "./PosCortes.vue";
import AddManualMovementModal from "./AddManualMovementModal";
import PosOpenCaja from "./PosOpenCaja";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "PosList",
  components: {
    AddPos,
  },
  directives: { cleave },
  props: ["type"],
  data() {
    return {
      perPage: 50,
      checkedPos: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE",
          label: "Clave de la caja",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "LISTA_PRECIOS_FOR",
          label: "Lista de precios",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {},
  mounted() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(this.$store.dispatch("GETSECRETS"));
      allPromises.push(
        this.$store.dispatch("GETPOS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
          warehouses: [],
        })
      );

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    async changeFilterValue() {
      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETPOS", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
          warehouses: [],
        })
      );

      await Promise.all(allPromises);
    },
    openAddPos() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPos,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openPosCortes(posInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PosCortes,
        props: {
          posInformation: posInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openOpenPos(posInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PosOpenCaja,
        props: {
          posInformation: posInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddManualMovement(posInformation, type) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddManualMovementModal,
        props: {
          posId: posInformation._id,
          type: type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    pos() {
      return this.$store.getters.POS.map((singlePos) => {
        const posWithProps = { ...singlePos };
        posWithProps.ALMACEN_FOR = posWithProps.ALMACEN.NOMBRE_ALM;
        posWithProps.LISTA_PRECIOS_FOR =
          posWithProps.LISTA_PRECIOS.CLAVE_LISTAP;

        if (posWithProps.STATUS === "CE") {
          posWithProps.STATUS_FOR = "Cerrada";
        }

        if (posWithProps.STATUS === "AB") {
          posWithProps.STATUS_FOR = "Abierta";
        }
        return posWithProps;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
