<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Cajas y Cortes </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Cajas" icon="printer-pos">
        <PosList :type="0" />
      </b-tab-item>
      <b-tab-item label="Cortes y detalles de cajas" icon="post">
        <PosList :type="1" />
      </b-tab-item>
      <b-tab-item label="Secretos" icon="form-textbox-password">
        <SecretsList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import PosList from "../components/PosList";
import SecretsList from "../components/SecretsList";

export default {
  name: "AllPosList",
  components: {
    PosList,
    SecretsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "posCortes");
  },
  async created() {
    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETPOS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(this.$store.dispatch("GETSECRETS"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
