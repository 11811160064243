/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Ventas realizadas en corte</h1>

    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control"></div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        <p>
          Cantidad de ventas: <b>{{ sales.length }}</b>
        </p>
        <p>
          Total de ventas neto: <b>{{ getTotalSales() }}</b>
        </p>
      </div>
      <b-table
        pagination-position="both"
        :data="sales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="sales"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay ventas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>No hay información que mostrar</p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { toFixedNoRound } from "../../../utils/fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "PosSaleList",
  props: ["salesList"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 10,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_VENTA",
            label: "Folio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_VENDEDOR",
            label: "Vendedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CAJERO",
            label: "Cajero",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFormatted",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFacFormatted",
            label: "Estatus de timbrado",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "posFormatted",
            label: "Punto de venta",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FORMA_P_FOR",
            label: "Forma de pago",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "POS_METODOS_PAGO",
            label: "Formas de pago",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_CLIENTE",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de la venta",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "SUBTOTAL",
            label: "Subtotal",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "IVA",
            label: "Iva total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "TOTAL",
            label: "Total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
        ],
        "pos-sale-list-columns"
      ),
      masks: Masks,
    };
  },
  async created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    getTotalSales() {
      return toFixedNoRound(
        this.sales.reduce((total, currentValue) => {
          return total + currentValue.TOTAL;
        }, 0),
        3
      );
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(newColumns, "pos-sale-list-columns");
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sales() {
      if (!this.salesList) {
        return [];
      }

      return this.salesList.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        if (singleSale.FORMA_P) {
          saleWithProps.FORMA_P_FOR = singleSale.FORMA_P.DESCRIBE_P;
        }

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }
        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }
        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );
        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Parcialmente timbrada";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Pendiente de timbrar";
        }

        return saleWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
